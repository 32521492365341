const refreshMiniCart = async () => {
    const {data} = await window.axios.post(window.wc_add_to_cart_params.wc_ajax_url.toString().replace('%%endpoint%%', 'get_refreshed_fragments'));
    const content = data.fragments['div.widget_shopping_cart_content'];

    $('[data-wc="mini-cart"]').html(content);
    $('[data-wc="mini-cart-button"]').trigger('click');

    let totalItems = 0;

    $(content).find('.quantity').each((i, qty) => {
        totalItems += parseInt(qty.innerText.split(' ', 1)[0]);
    });

    $('[data-wc="cart-count"]').text(totalItems);
};

(function ($) {
    $(function () {
        $('body').on('removed_from_cart', function () {
            refreshMiniCart();
        });

        $('body').on('submit', 'form.cart', async function (e) {
            e.preventDefault();

            const $btn = $(':submit', this);
            $btn.addClass('loading');

            const quantity = {
                products: []
            };

            for (const element of e.target.elements) {
                if (['quantity', 'add-to-cart', 'product_id', 'variation_id'].includes(element.name)) {
                    quantity[element.name] = element.value;
                } else if (element.name.startsWith('quantity[')) {
                    const id = element.name.match(/\d+/)[0];
                    const qty = parseInt(element.value);

                    if (qty > 0) {
                        quantity.products.push({product_id: id, quantity: qty})
                    }
                }
            }

            if (!!quantity.variation_id) {
                quantity.product_id = quantity.variation_id;
                delete quantity.variation_id;

                if (!!quantity['add-to-cart']) {
                    delete quantity['add-to-cart'];
                }
            } else if (!!quantity['add-to-cart']) {
                quantity.product_id = quantity['add-to-cart'];
                delete quantity['add-to-cart'];
            }

            if (!quantity.products.length) {
                const qty = parseInt(quantity.quantity);

                if (qty > 0) {
                    quantity.products.push({
                        quantity: qty, product_id: quantity.product_id,
                    });
                }
            }

            delete quantity.quantity;
            delete quantity.product_id;

            await Promise.all(quantity.products.map((product) => {
                const data = new FormData();

                data.append('product_id', product.product_id);
                data.append('quantity', product.quantity);

                return window.axios.post(window.wc_add_to_cart_params.wc_ajax_url.toString().replace('%%endpoint%%', 'add_to_cart'), data)
            }));

            await refreshMiniCart(true);

            $btn.removeClass('loading');
        });
    });
})(window.jQuery);
